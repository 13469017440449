import Vue from 'vue';
import VueFullPage from 'vue-fullpage.js';
import 'animate.css';
import VueGtag from 'vue-gtag';
import App from './App.vue';

Vue.use(VueGtag, {
  config: { id: 'G-BNP1Q8EPJ2' },
});

Vue.config.productionTip = false;
Vue.use(VueFullPage);

new Vue({
  render: (h) => h(App),
}).$mount('#app');
